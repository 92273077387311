export default function CarsayoLogo({
  fill,
  width,
  height,
}: {
  fill?: string
  width?: string
  height?: string
}) {
  return (
    <svg
      width={width || '220'}
      height={height || '37'}
      viewBox="0 0 220 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_398_52201)">
        <path
          d="M184.438 3.55527C184.438 1.6211 182.924 0 180.919 0C179.968 0 178.855 0.451477 178.202 1.27426L169.189 12.0928C168.989 12.3333 168.619 12.3333 168.419 12.0928L161.012 1.3789C160.256 0.463291 159.201 0.0118143 158.101 0.0118143C156.038 0.0118143 154.376 1.63291 154.376 3.77637C154.376 4.64473 154.583 5.4557 155.236 6.26667L164.319 19.0295C164.692 19.4793 164.896 20.0447 164.896 20.6295V32.7789C164.896 34.8641 166.604 36.5899 168.666 36.5899C170.729 36.5899 172.438 34.8641 172.438 32.7789V20.27C172.438 19.6861 172.642 19.1198 173.015 18.67L183.647 5.84894C184.197 5.18903 184.449 4.48186 184.449 3.56709L184.438 3.55527Z"
          fill={fill || 'black'}
        />
        <path
          d="M201.238 0C190.999 0 182.944 8.07595 182.944 18.2945C182.944 28.5131 190.999 36.589 201.238 36.589C211.478 36.589 219.533 28.5131 219.533 18.2945C219.533 8.07595 211.478 0 201.238 0ZM201.238 29.2203C195.414 29.2203 190.802 24.389 190.802 18.2945C190.802 12.2 195.413 7.36878 201.238 7.36878C207.064 7.36878 211.675 12.2 211.675 18.2945C211.675 24.389 207.064 29.2203 201.238 29.2203Z"
          fill={fill || 'black'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 18.2945C0 8.10211 8.08017 0 18.2945 0C22.3367 0 26.0734 1.27426 29.1046 3.47342C30.7848 4.69367 31.1578 7.04388 29.9384 8.72405C28.719 10.4042 26.3679 10.7772 24.6878 9.55781C22.9283 8.28101 20.7333 7.51814 18.2945 7.51814C12.238 7.51814 7.51814 12.2489 7.51814 18.2945C7.51814 24.3401 12.238 29.0709 18.2945 29.0709C20.7342 29.0709 22.9283 28.3089 24.6878 27.0312C26.3679 25.8118 28.719 26.1848 29.9384 27.8658C31.1578 29.546 30.7848 31.897 29.1046 33.1165C26.0734 35.3165 22.3376 36.5899 18.2945 36.5899C8.08017 36.589 0 28.4878 0 18.2945Z"
          fill={fill || 'black'}
        />
        <path
          d="M112.25 36.589C105.647 36.589 101.66 33.6979 100.394 30.0456C100.12 29.3106 99.9937 28.5511 99.9937 27.816C99.9937 25.5612 101.386 24.0422 103.348 24.0422C104.846 24.0422 105.858 24.6549 106.849 26.4194C107.883 28.6245 109.93 29.5561 112.376 29.5561C115.393 29.5561 117.355 27.9882 117.355 25.8312C117.355 23.8709 116.068 22.8658 112.461 22.0574L109.318 21.3705C103.264 20.0473 100.099 16.5916 100.099 11.2C100.099 4.3865 105.184 0 112.187 0C118.009 0 122.27 2.59747 123.747 7.0827C123.957 7.69536 124.064 8.28354 124.064 9.01857C124.064 11.1021 122.756 12.4987 120.815 12.5232C119.127 12.5232 118.073 11.8127 117.165 10.097C116.173 7.89114 114.486 7.03376 112.25 7.03376C109.444 7.03376 107.799 8.50464 107.799 10.5873C107.799 12.4498 109.17 13.4785 112.482 14.2143L115.605 14.9004C122.102 16.3468 125.055 19.4101 125.055 24.973C125.055 32.0802 120.266 36.589 112.25 36.589Z"
          fill={fill || 'black'}
        />
        <path
          d="M47.529 2.16606C48.4067 0.289267 50.6429 -0.523392 52.5248 0.352558C54.4067 1.22766 55.2202 3.45889 54.3434 5.33652L40.3965 34.4226C39.5189 36.2994 37.2826 37.112 35.4008 36.2361C33.5189 35.361 32.7046 33.1298 33.5822 31.2521L47.529 2.16606Z"
          fill={fill || 'black'}
        />
        <path
          d="M54.3431 2.16606C53.4663 0.289267 51.2292 -0.523392 49.3473 0.352558C47.4655 1.22766 46.652 3.45889 47.5296 5.33652L61.4764 34.4226C62.3532 36.2994 64.5904 37.112 66.4722 36.2361C68.3541 35.361 69.1676 33.1298 68.2908 31.2521L54.3431 2.16606Z"
          fill={fill || 'black'}
        />
        <path
          d="M54.2823 29.0726H65.1574V36.5908H54.2823C52.268 36.5908 50.6216 34.9123 50.6216 32.8313C50.6216 30.7503 52.2562 29.0718 54.2823 29.0718V29.0726Z"
          fill={fill || 'black'}
        />
        <path
          d="M140.608 2.16606C141.485 0.289267 143.722 -0.523392 145.604 0.352558C147.486 1.22766 148.299 3.45889 147.422 5.33652L133.475 34.4226C132.598 36.2994 130.361 37.112 128.479 36.2361C126.597 35.361 125.784 33.1298 126.66 31.2521L140.608 2.16606Z"
          fill={fill || 'black'}
        />
        <path
          d="M147.423 2.16606C146.545 0.289267 144.309 -0.523392 142.427 0.352558C140.545 1.22766 139.731 3.45889 140.608 5.33652L154.555 34.4226C155.433 36.2994 157.669 37.112 159.551 36.2361C161.433 35.361 162.247 33.1298 161.369 31.2521L147.423 2.16606Z"
          fill={fill || 'black'}
        />
        <path
          d="M147.362 29.0726H158.238V36.5908H147.362C145.348 36.5908 143.702 34.9123 143.702 32.8313C143.702 30.7503 145.336 29.0718 147.362 29.0718V29.0726Z"
          fill={fill || 'black'}
        />
        <path
          d="M75.4373 36.5797C77.4896 36.5797 79.1884 34.8304 79.1884 32.7164V9.02025C79.1884 7.91392 80.0863 7.01603 81.1934 7.01603H85.5943C87.7943 7.01603 89.7192 8.75865 89.7192 11.0245C89.7192 13.0211 88.5985 14.5367 86.5934 14.7823C86.8441 15.0878 92.2255 20.2996 92.2255 20.2996C94.9732 19.1721 97.3377 16.2287 97.3377 11.3316C97.3377 5.0962 92.6407 0 85.8339 0H75.4263C73.374 0 71.6753 1.74937 71.6753 3.86329V32.7038C71.6753 34.8177 73.374 36.5671 75.4263 36.5671L75.4373 36.5789V36.5797Z"
          fill={fill || 'black'}
        />
        <path
          d="M90.1452 16.5201L92.2254 20.299L93.9798 24.0585L97.3376 31.0754C98.3579 32.8425 97.7283 35.1167 95.93 36.1547C94.1326 37.1918 91.8473 36.6011 90.8262 34.8332L83.6338 20.2771C82.6431 18.5623 83.2878 16.3226 85.0895 15.2821C86.8811 14.2484 89.1486 14.7943 90.1452 16.5192V16.5201Z"
          fill={fill || 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_398_52201">
          <rect width="220" height="37" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
