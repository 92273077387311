import CarsayoLogo from "../components/icons/CarsayoLogo";
import MainPage from "./main/main";

export default function MainLayout() {
  return (
    <div className="w-dvw h-dvh overflow-y-auto no-scrollbar">
      <header className="w-full bg-white py-[18px] px-5 flex justify-start items-center gap-1.5 h-[60px] fixed top-0 left-0 z-50">
        <CarsayoLogo width="96" height="16" />
        <img
          src="/imgs/studio_logo.png"
          alt="로고이미지"
          className="h-[20px] object-contain "
        />
      </header>
      <div className="pt-[60px] min-h-dvh">
        <MainPage />
      </div>
    </div>
  );
}
